import { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { MultiSelect } from "components/Inputs/MultiSelect";
import {
  formatSeasons,
  formatOptions,
} from "components/Form/CourseDetailsForm/CourseDetailsHelpers";
import { Legend, Section } from "components/Form/sections";
import {
  requestSeasons,
  requestCourses,
} from "components/Form/CourseDetailsForm/CourseDetailsApi";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import { courseTenures } from "components/EducationLoan/SelectOptions";
import useLogin from "lib/hooks/useLogin";
import { CountryCode } from "lib/DataMapper/CountryCode";

const CourseDetailsSection = ({ application }) => {
  const [seasons, setSeasons] = useState([]);
  const [courses, setCourses] = useState([]);
  const { logout } = useLogin();

  const fetchSeasons = async (country) => {
    if (country) {
      try {
        const res = await requestSeasons(country);
        setSeasons(res?.data?.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      setSeasons([]);
    }
  };

  const fetchCourses = async (campusId) => {
    if (campusId) {
      try {
        const res = await requestCourses(campusId);
        const sortedData = orderBy(
          res.data.filter((_o) => !isEmpty(_o.name)),
          "name",
          "asc"
        );
        setCourses(sortedData);
      } catch (err) {
        if (
          err &&
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        )
          logout();
        console.error(err);
      }
    } else {
      setCourses([]);
    }
  };
  useEffect(() => {
    fetchSeasons(CountryCode.USA);
  }, []);

  useEffect(() => {
    application?.applicationCourseDetails?.campus?.id &&
      fetchCourses(application?.applicationCourseDetails?.campus?.id);
  }, [application]);
  return (
    <Section topPadding>
      <Legend>
        What course will you pursue at{" "}
        {application?.applicationCourseDetails?.campus?.name}?
      </Legend>
      <div className="mb-6">
        <Field
          name="courseDetails.courseId"
          component={MultiSelect}
          label="Course"
          placeholder="Select Course"
          options={courses.map(formatOptions)}
          validate={(v) => {
            if (!v) return "Please select a course";
          }}
          needTopSpacing
        />
      </div>
      <div className="md:flex md:-mx-2 md:mb-8">
        <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
          <Field
            name="courseDetails.durationInMonths"
            component={MultiSelect}
            options={courseTenures()}
            label="Duration of Course"
            placeholder="Select Duration of Course"
            isRequired
            validate={(v) => {
              if (!v) return "Please select the duration of the course";
            }}
            needTopSpacing
          />
        </div>
        <div className="md:w-1/2 px-1 md:px-2">
          <Field
            name="courseDetails.season"
            component={MultiSelect}
            label="Course Season"
            placeholder="Select a Course Season"
            options={seasons.map(formatSeasons)}
            validate={(v) => {
              if (!v) return "Course season is required";
            }}
          ></Field>
        </div>
      </div>
    </Section>
  );
};

export default CourseDetailsSection;
