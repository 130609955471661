import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import { wrapper } from "../redux/store";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import { saveUtmData } from "../lib/utils/utm_utils";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { isBrowserClient } from "lib/utils/commonUtils";
import { Provider } from "react-redux";

if (isBrowserClient()) {
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      const { status } = error.response || {};
      if (status === 403 && window?.location?.pathname !== "/") {
        window?.open("/api/logout", "_self");
      } else {
        return Promise.reject(error);
      }
    }
  );
}

const theme = createTheme();

const captureAndSaveUtm = (query) => {
  const utmSource = query?.utm_source;
  const utmCampaign = query?.utm_campaign;
  const utmTerm = query?.utm_term;
  const refereeCode = query?.referralCode;
  const adId = query?.ad_id;
  const userContext = query?.user_context;
  saveUtmData(null, {
    utmSource,
    utmCampaign,
    utmTerm,
    refereeCode,
    adId,
    userContext,
  });
};

const MyApp = ({ Component, pageProps, router }) => {
  const { store } = wrapper.useWrappedStore(pageProps);
  const [path, setPath] = useState("");

  const storePathValues = () => {
    try {
      const storage = window && window?.sessionStorage;
      if (!storage) return;
      // Set the previous path as the value of the current path.
      const prevPath = storage.getItem("currentPath");
      storage.setItem("prevPath", prevPath);
      // Set the current path value by looking at the browser's location object.
      storage.setItem("currentPath", window?.location.pathname);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.GTM_ID,
    });
    hotjar.initialize(process.env.HOTJAR_SITEID, 6);
    storePathValues();
  }, []);

  useEffect(() => {
    captureAndSaveUtm(router.query);
  }, [router.query]);

  useEffect(() => {
    if (path !== router.asPath) {
      setPath(router.asPath);
      storePathValues();
    }
  }, [router.asPath]);

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default MyApp;
