import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  fetchOfferDetails,
  saveOfferDetails,
  switchToCosignerDetailsStage,
  updateApplication,
  updateInitialValues,
  updateIsPageLoading,
  updateIsSaving,
  updateIsSwitchingStage,
} from "components/Form/LoanOfferScreen/redux/OfferDetailsSlice";
import {
  requestOfferDetails,
  requestSaveOfferDetails,
  requestSwitchToCosignerDetailsStage,
} from "components/Form/LoanOfferScreen/redux/OfferDetailsApi";
import Router from "next/router";
import { eventName, triggerInschoolTrackForm } from "lib/utils/events_utils";
import { trackEvent } from "scripts/segment";
import { fireEvent } from "utils/ga";
import { logEvent } from "components/Analytics/google-analytics";

const getStore = (store) => store?.offerDetails;

function* handleFetchOfferDetails(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestOfferDetails, action.payload);
    if (res.data) {
      yield put(updateInitialValues(res?.data?.application));
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleSwitchToCosignerDetailsStage(action) {
  try {
    yield put(updateIsSwitchingStage(true));
    const slug = action.payload;
    const res = yield call(requestSwitchToCosignerDetailsStage, slug);
    if (res?.data?.success) {
      Router.push(`/applications/${slug}/${res?.data?.data?.toLowerCase()}`);
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSwitchingStage(false));
  }
}

function* handleSaveOfferDetails(action) {
  try {
    yield put(updateIsSaving(true));
    const res = yield call(requestSaveOfferDetails, action.payload);
    const { application } = yield select(getStore);
    yield call(triggerInschoolTrackForm, {
      values: action.payload,
      applicationId: application?.id,
      screen: application?.applicationState?.screen,
      nextStage: res?.success && res?.data,
    });
    if (res.success) {
      yield call(
        logEvent,
        "Page Completed",
        `${application?.applicationState?.screen} completed`,
        `${application?.slug}`
      );
      yield call(trackEvent, eventName.IS_LOAN_OFFER_ACCEPTED);
      yield call(fireEvent, eventName.IS_LOAN_OFFER_ACCEPTED);
      Router.push(
        `/applications/${action.payload.slug}/${res?.data?.toLowerCase()}`
      );
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* offerDetailsSaga() {
  yield takeLatest(fetchOfferDetails, handleFetchOfferDetails);
  yield takeLatest(
    switchToCosignerDetailsStage,
    handleSwitchToCosignerDetailsStage
  );
  yield takeLatest(saveOfferDetails, handleSaveOfferDetails);
}

export default offerDetailsSaga;
